<ol class="breadcrumb m_hide" *ngIf="showUrl == '/reports'">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li>Reports</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl == '/admins'">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li>Admins</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl == '/clients'">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li>Clients</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl == '/brokers'">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li>Brokers</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl == '/quotes' && renewalQuotesList == '0'">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li>Quotes</li>
</ol>
<ol class="breadcrumb m_hide" *ngIf="showUrl == '/renewal-quotes' && renewalQuotesList == '1'">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li><a href="javascript:void(0);" (click)="goToQuotes()">Quotes</a></li>
    <li>Renewal Quotes list</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl == '/expired-quotes' && expiredQuotesList == '1'">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li><a href="javascript:void(0);" (click)="goToQuotes()">Quotes</a></li>
    <li>Expired Quotes list</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl == '/broker-quotes'">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li><a href="javascript:void(0);" (click)="goToQuotes()">Quotes</a></li>
    <li>Quotes list</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl == '/tutorials'">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li>Tutorials</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl == '/mail-signatures'">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li>Mail signatures</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl == '/mail-templates'">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li>Mail templates</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl == '/broker'">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li *ngIf="loggedUserRole == 1 || loggedUserRole == 2"><a href="javascript:void(0);" (click)="goToBrokers()"> Brokers</a></li>
    <li>Broker</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl.includes('/claims')">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li *ngIf="loggedUserRole == 1 || loggedUserRole == 2 || loggedUserRole == 3"><a href="javascript:void(0);" (click)="goToQuotes()"> Quotes</a></li>
    <li><a href="javascript:void(0);" (click)="goToQuoteSummaryTab()">{{ quoteId }}</a></li>
    <li><a href="javascript:void(0);" (click)="goToQuoteClaimsTab()"> Claims</a></li>
    <li>Claim</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl.includes('/inspections')">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li *ngIf="loggedUserRole == 1 || loggedUserRole == 2 || loggedUserRole == 3"><a href="javascript:void(0);" (click)="goToQuotes()"> Quotes</a></li>
    <li><a href="javascript:void(0);" (click)="goToQuoteSummaryTab()">{{ quoteId }}</a></li>
    <li><a href="javascript:void(0);" (click)="goToQuoteClaimsTab()"> Inspections</a></li>
    <li>Inspection</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl.includes('/audits')">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li *ngIf="loggedUserRole == 1 || loggedUserRole == 2 || loggedUserRole == 3"><a href="javascript:void(0);" (click)="goToQuotes()"> Quotes</a></li>
    <li><a href="javascript:void(0);" (click)="goToQuoteSummaryTab()">{{ quoteId }}</a></li>
    <li><a href="javascript:void(0);" (click)="goToQuoteClaimsTab()"> Audits</a></li>
    <li>Audit</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl.includes('/quote-summary') || showUrl.includes('/quote-policy') || showUrl.includes('/quote-underwriting') || showUrl.includes('/insured-info') || showUrl.includes('/quote-irpm') || showUrl.includes('/quote-history') || showUrl.includes('/quote-claims') || showUrl.includes('/eo-quote-summary') || showUrl.includes('erma-quote-summary') ">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2 || loggedUserRole == 3) && renewalQuotesList == '0' && expiredQuotesList == '0'"><a href="javascript:void(0);" (click)="goToQuotes()">Quotes</a></li>
    <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2 || loggedUserRole == 3) && renewalQuotesList == '1' && expiredQuotesList == '0'"><a href="javascript:void(0);" (click)="goToRenewalQuotes()">Renewal Quotes</a></li>
    <li *ngIf="(loggedUserRole == 1) && expiredQuotesList == '1' && renewalQuotesList == '0'"><a href="javascript:void(0);" (click)="goToExpiredQuotes()">Expired Quotes</a></li>
    <li>{{ quoteId == 0 ? 'New quote' : quoteId }}</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl.includes('/request-endorsement')">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2 || loggedUserRole == 3) && renewalQuotesList == '0' && expiredQuotesList == '0' "><a href="javascript:void(0);" (click)="goToQuotes()">Quotes</a></li>
    <li><a href="javascript:void(0);" (click)="goToQuoteSummaryTab()">{{ quoteId }}</a></li>
    <li>Request Endorsement</li>
</ol>
<!-- wc -->
<ol class="breadcrumb m_hide" *ngIf="showUrl.includes('/workers-compensation')">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2 || loggedUserRole == 3) && renewalQuotesList == '0' && expiredQuotesList == '0' "><a href="javascript:void(0);" (click)="goToQuotes()">Quotes</a></li>
    <li><a href="javascript:void(0);" (click)="goToQuoteSummaryTab()">{{ quoteId }}</a></li>
</ol>
<!-- IMU -->
<ol class="breadcrumb m_hide" *ngIf="showUrl.includes('/contractors-equipment')">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2 || loggedUserRole == 3) && renewalQuotesList == '0' && expiredQuotesList == '0' "><a href="javascript:void(0);" (click)="goToQuotes()">Quotes</a></li>
    <li><a href="javascript:void(0);" (click)="goToQuoteSummaryTab()">{{ quoteId }}</a></li>
</ol>
<!-- <ol class="breadcrumb m_hide" *ngIf="showUrl.includes('/eo-quote-summary')">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2 || loggedUserRole == 3) && renewalQuotesList == '0' && expiredQuotesList == '0'"><a href="javascript:void(0);" (click)="goToQuotes()">Quotes</a></li>
    <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2 || loggedUserRole == 3) && renewalQuotesList == '1' && expiredQuotesList == '0'"><a href="javascript:void(0);" (click)="goToRenewalQuotes()">Renewal Quotes</a></li>
    <li>{{ quoteId == 0 ? 'New quote' : quoteId }}</li>
</ol> -->
<ol class="breadcrumb m_hide" *ngIf="showUrl.includes('/contractors-eco')">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li><a href="javascript:void(0);" (click)="goToQuotes()">Quotes</a></li>
    <li>{{ quoteId == 0 ? 'New quote' : quoteId }}</li>
</ol>

<ol class="breadcrumb m_hide" *ngIf="showUrl.includes('/erma-payroll-rater')">
  <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
  <li><a href="javascript:void(0);" (click)="goToQuotes()">Quotes</a></li>
  <li>{{ quoteId == 0 ? 'New quote' : quoteId }}</li>
</ol>
<ol class="breadcrumb m_hide" *ngIf="showUrl.includes('/non-contractors-gl')">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li><a href="javascript:void(0);" (click)="goToQuotes()">Quotes</a></li>
    <li>{{ quoteId == 0 ? 'New quote' : quoteId }}</li>
</ol>
<ol class="breadcrumb m_hide" *ngIf="showUrl.includes('/payments-tab')">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li>payments-tab</li>
</ol>
<ol class="breadcrumb m_hide" *ngIf="showUrl.includes('/request-excess')">
    <li><a href="javascript:void(0);" (click)="goToHome()"><i class="fa fa-dashboard"></i> Home</a></li>
    <li *ngIf="(loggedUserRole == 1 || loggedUserRole == 2 || loggedUserRole == 3)"><a href="javascript:void(0);" (click)="goToQuotes()">Quotes</a></li>
    <li><a href="javascript:void(0);" (click)="goToQuoteSummaryTab()">{{ quoteId }}</a></li>
    <li>Request Excess</li>
</ol>
